import { ProfileType } from '@dltru/dfa-models'
import cn from 'classnames'
import { WrappedInput, WrappedInputProps } from '../../../../../components/Form'
import { documentValidators, InnCheck } from '../../../../../utils'

const defaultCheckInnExistenceText =
    'Вы не можете передать права по указанному ИНН. Если ИНН введен корректно, свяжитесь с получателем прав для выяснения его статуса на платформе.'
const defaultCheckInnNotExistenceText = 'ИНН уже зарегистрирован'

type ComponentProps = Omit<WrappedInputProps, 'name'> & {
    isUl?: boolean
    name?: WrappedInputProps['name']
    checkInnExistenceFor?: ProfileType
    checkInnExistenceText?: string
    checkInnNotExistenceFor?: ProfileType
    checkInnNotExistenceText?: string
    noAvailableInn?: string | number | undefined | null
    api: any // TODO: надо решить с инжектом api
}

const getCheckInnType = (
    isCheckForInnExistence: boolean,
    isCheckForInnNotExistence: boolean,
): InnCheck => {
    if (isCheckForInnExistence) {
        return InnCheck.checkInnExist
    }
    if (isCheckForInnNotExistence) {
        return InnCheck.checkInnNotExist
    }
    return InnCheck.none
}

export const InnInput = ({
    checkInnExistenceText = defaultCheckInnExistenceText,
    checkInnNotExistenceText = defaultCheckInnNotExistenceText,
    checkInnExistenceFor,
    checkInnNotExistenceFor,
    label,
    name,
    placeholder,
    className,
    isUl,
    api,
    noAvailableInn,
    ...rest
}: ComponentProps) => {
    const count = isUl ? 10 : 12
    return (
        <WrappedInput
            {...rest}
            className={cn(className ?? 'formComponentItem', {
                'bigger-error-field':
                    checkInnExistenceFor ||
                    Boolean(
                        checkInnNotExistenceFor &&
                            !(checkInnNotExistenceText === defaultCheckInnNotExistenceText),
                    ),
            })}
            label={label ?? 'ИНН'}
            name={name ?? 'inn'}
            placeholder={placeholder ?? `Состоит из ${count} цифр`}
            rules={[
                {
                    validator: documentValidators.innWithExistenceCheck(
                        count,
                        checkInnExistenceText,
                        checkInnNotExistenceText,
                        getCheckInnType(
                            Boolean(checkInnExistenceFor),
                            Boolean(checkInnNotExistenceFor),
                        ),
                        api.lib.checkExistenceOfInnService,
                        checkInnExistenceFor || checkInnNotExistenceFor,
                        noAvailableInn,
                    ),
                },
            ]}
            format={isUl ? '##########' : '############'}
        />
    )
}
