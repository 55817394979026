// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".descriptionList_WcnRC {\n  margin: 0;\n  padding-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/roleForms/steps/legalEntitySteps/LegalEntityStep7/LegalEntityStep7.m.less"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kBAAA;AACJ","sourcesContent":[".descriptionList {\n    margin: 0;\n    padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionList": "descriptionList_WcnRC"
};
export default ___CSS_LOADER_EXPORT___;
