import { MemberStatusType, TypeUserType } from '@dltru/dfa-models'
import { FC, Fragment, useEffect, useState } from 'react'
import { Button } from '../../../../../button'
import { WrappedInput, WrappedSelect } from '../../../../../components/Form'
import { Divider } from '../../../../../Divider'
import { Space } from '../../../../../Space'
import { commonValidators } from '../../../../../utils'
import { FioForm } from '../../../FioForm'
import { memberStatusOptions, typeUserOptions } from './consts'
import { InnInput } from './InnInput'

interface ILegalEntityItemProps {
    key: number
    name: number
    listName: string
    uneditable: boolean
    fieldKey: number
    isDelete: boolean
    memberStatusDefault: MemberStatusType
    userTypeDefault: TypeUserType
    remove: (index: number | number[]) => void
    api: any
    isUl?: boolean
}

export const LegalEntityItem: FC<ILegalEntityItemProps> = ({
    key,
    name,
    isDelete,
    memberStatusDefault,
    userTypeDefault,
    remove,
    api,
    isUl,
    ...props
}) => {
    const [memberStatus, setMemberStatus] = useState<MemberStatusType>()
    const [userType, setUserType] = useState<TypeUserType>(userTypeDefault)

    useEffect(() => {
        if (memberStatusDefault) {
            setMemberStatus(memberStatusDefault)
        }
    }, [memberStatusDefault])

    useEffect(() => {
        setUserType(userTypeDefault)
    }, [userTypeDefault])

    const isShowJobTitle =
        userType === TypeUserType.BUSINESSMAN ||
        userType === TypeUserType.PERSON ||
        memberStatus === MemberStatusType.MEMBER

    const isShowFio =
        userType === TypeUserType.BUSINESSMAN ||
        userType === TypeUserType.PERSON ||
        memberStatus === MemberStatusType.MEMBER

    return (
        <Fragment key={key}>
            <Divider orientation="left" margin={[32, 0]}>
                <Space>
                    Участник
                    {isDelete && (
                        <Button type="link" size="small" onClick={() => remove(name)}>
                            Удалить
                        </Button>
                    )}
                </Space>
            </Divider>
            <div className="formRow">
                <WrappedSelect
                    {...props}
                    label="Статус участника"
                    className="formComponentItem"
                    name={[name, 'status']}
                    options={memberStatusOptions}
                    required
                    onChange={setMemberStatus}
                />
            </div>
            {memberStatus === MemberStatusType.SHARER && (
                <div className="formRow">
                    <WrappedSelect
                        {...props}
                        label="Тип пользователя"
                        className="formComponentItem"
                        name={[name, 'type_user']}
                        options={typeUserOptions}
                        required
                        onChange={setUserType}
                    />
                </div>
            )}

            {isShowJobTitle && (
                <div className="formRow">
                    <WrappedInput
                        {...props}
                        className="formComponentItem"
                        label="Должность (при наличии)"
                        name={[name, 'job_title']}
                        rules={[
                            {
                                validator: commonValidators.stringWithSpacesAndDash(),
                            },
                        ]}
                    />
                </div>
            )}

            {isShowFio && <FioForm {...props} noBottomDivider />}
            {userType === TypeUserType.LEGAL && (
                <>
                    <div className="formRow">
                        <WrappedInput
                            {...props}
                            className="formComponentItem"
                            label="Наименование"
                            name={[name, 'title_name']}
                            required
                            rules={[
                                {
                                    validator: commonValidators.stringWithSpacesAndDash(),
                                },
                            ]}
                        />
                    </div>
                    <InnInput
                        {...props}
                        isUl={isUl}
                        api={api}
                        className="formComponentItem"
                        name={[name, 'inn']}
                        required
                    />
                </>
            )}
        </Fragment>
    )
}
