import { MemberStatusType, TypeUserType } from '@dltru/dfa-models'
import { FC, useContext, useEffect, useState } from 'react'

import { Alert } from '../../../../../Alert'
import { Box } from '../../../../../Box'
import { Step, StepperContext, useEditable } from '../../../../../Stepper'
import { Button } from '../../../../../button'
import { CardDivider } from '../../../../../card'
import { Form, WrappedInput } from '../../../../../components/Form'
import { IRoleFormStepProps } from '../../types'
import { description, LIST_NAME, MAX_COUNT, STEP_INDEX } from './consts'
import { LegalEntityItem } from './LegalEntityItem'

export const LegalEntityStep7: FC<IRoleFormStepProps> = ({ isEditAllow, api }) => {
    const [form] = Form.useForm()

    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const [memberStatus, setMemberStatus] = useState<MemberStatusType[]>(
        (values?.[LIST_NAME] as Array<{ status: MemberStatusType }>)?.map(({ status }) => status) ??
            [],
    )

    const [usersTypes, setUsersTypes] = useState<TypeUserType[]>(
        (values?.[LIST_NAME] as Array<{ type_user: TypeUserType }>)?.map(
            ({ type_user }) => type_user,
        ) ?? [],
    )

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    useEffect(() => {
        if (!(values?.[LIST_NAME] as Array<{ status: MemberStatusType }>)?.length) {
            form.setFieldsValue({ [LIST_NAME]: [{}] })
        }
    }, [])

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Структура и персональный состав органов управления юридического лица"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem--xl"
                    multiline
                    label="Организационная структура юридического лица"
                    name="organizational_structure"
                    uneditable={isEdit}
                    required
                    maxLength={200}
                />
            </div>
            {!isEdit && (
                <Box margin={[0, 0, 0, 240]}>
                    <Alert type="info" showIcon description={description} banner width={600} />
                </Box>
            )}
            <Form.List name={LIST_NAME}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            const props = {
                                ...restField,
                                listName: LIST_NAME,
                                uneditable: isEdit,
                                fieldKey: name,
                            }

                            const isDelete = fields.length > 1 && !isEdit

                            return (
                                <LegalEntityItem
                                    {...props}
                                    api={api}
                                    name={name}
                                    key={key}
                                    isDelete={isDelete}
                                    userTypeDefault={usersTypes[key]}
                                    memberStatusDefault={memberStatus[key]}
                                    remove={remove}
                                    isUl={true}
                                />
                            )
                        })}
                        {!isEdit && fields?.length < MAX_COUNT && (
                            <>
                                <CardDivider />
                                <Button type="link" onClick={() => add()}>
                                    Добавить Участника
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Form.List>
        </Step>
    )
}
