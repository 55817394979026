import { MemberStatusType, TypeUserTitle, TypeUserType } from '@dltru/dfa-models'
import { DefaultOptionType } from '../../../../../components/Form'
import styles from './LegalEntityStep7.m.less'

export const STEP_INDEX = 6
export const MAX_COUNT = 100
export const LIST_NAME = 'members'

export const description = (
    <>
        <span>Для акционерного общества:</span>
        <ul className={styles.descriptionList}>
            <li>общее собрание акционеров акционерного общества;</li>
            <li>совет директоров (наблюдательный совет) акционерного общества;</li>
            <li>
                единоличный исполнительный орган акционерного общества (директор, генеральный
                директор);
            </li>
            <li>коллегиальный исполнительный орган акционерного общества (правление, дирекция);</li>
        </ul>
        <span>Для Общества с ограниченной ответственностью: </span>
        <ul className={styles.descriptionList}>
            <li>общее собрание участников общества с ограниченной ответственностью;</li>
            <li>
                совет директоров (наблюдательный совет) общества с ограниченной ответственностью;
            </li>
            <li>
                единоличный исполнительный орган общества с ограниченной ответственностью
                (генеральный директор, президент и другие);
            </li>
            <li>
                коллегиальный исполнительный орган общества с ограниченной ответственностью
                (правление, дирекция и другие).
            </li>
        </ul>
    </>
)

export const memberStatusOptions: DefaultOptionType[] = [
    { value: MemberStatusType.MEMBER, label: MemberStatusType.MEMBER },
    { value: MemberStatusType.SHARER, label: MemberStatusType.SHARER },
]

export const typeUserOptions: DefaultOptionType[] = [
    { value: TypeUserType.LEGAL, label: TypeUserTitle.LEGAL },
    { value: TypeUserType.PERSON, label: TypeUserTitle.PERSON },
    { value: TypeUserType.BUSINESSMAN, label: TypeUserTitle.BUSINESSMAN },
]
